import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 44;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>だいちゃんの健康チャレンジ </li>
        <li>チャレンジ失敗してますよね </li>
        <li>人は人のことって無関心だよね </li>
        <li>モチベーションを保つのは難しい </li>
        <li>ダッチオーブンは続けてるよ </li>
        <li>時間がない人にいいHIITというトレーニング法 </li>
        <li>壮大なサクセスストーリーのプロローグ </li>
        <li>料理の研究もしていこうと思います </li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
